import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import store from "@/store"
import AccountService from "@/core/services/AccountService"
import { Mutations, Actions } from "@/store/enums/StoreEnums"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/account/:id",
        name: "entry",
        redirect: to => ({
          name: "status",
          params: { id: to.params.id },
        })
      },
      {
        path: "/account/:id/status",
        name: "status",
        component: () => import("@/views/pages/Status.vue")
      },
      {
        path: "/account/:id/connections",
        name: "leads",
        component: () => import("@/views/pages/Connections.vue")
      },
      {
        path: "/account/:id/displays-and-features",
        name: "displays-and-features",
        component: () => import("@/views/pages/DisplaysAndFeatures.vue")
      },
      {
        path: "/account/:id/locations",
        name: "locations",
        component: () => import("@/views/pages/Locations.vue")
      },
      {
        path: "/account/:id/users",
        name: "users",
        component: () => import("@/views/pages/Users.vue")
      },
      {
        path: "/account/:id/settings",
        name: "settings",
        component: () => import("@/views/pages/Settings.vue")
      },
      {
        path: "/account/:id/api-keys",
        name: "api-keys",
        component: () => import("@/views/pages/APIKeys.vue")
      },
      {
        path: "/account/:id/call-tracking",
        name: "call-tracking",
        component: () => import("@/views/pages/CallTracking.vue")
      },
      {
        path: "/account/:id/team-members",
        name: "team-members",
        component: () => import("@/views/pages/TeamMembers.vue")
      },
      {
        path: "/account/:id/performance-reports",
        name: "performance-reports",
        component: () => import("@/views/pages/PerformanceReports.vue")
      },
      {
        path: "/account/:id/performance-report/:yearMonth",
        name: "performance-report-detail",
        component: () => import("@/views/pages/PerformanceReportDetail.vue")
      },
    ]
  },
  {
    path: "/account-select",
    name: "account-select",
    component: () => import("@/views/pages/AccountSelect.vue")
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue"),
    beforeEnter(to, from, next) {
      store.dispatch(Actions.LOGIN);
    }
  },
  {
    path: "/callback",
    name: "callback",
    component: () => import("@/views/auth/Callback.vue"),
    beforeEnter(to, from, next) {
      const currUrl = window.location.href
      store.dispatch(Actions.PARSE, currUrl)
    }
  },
  {
    path: "/rx/hl-integration/callback",
    name: "ghl-callback",
    component: () => import("@/views/auth/GHLCalback.vue"),
    meta: {
      noAuth: true
    },
    beforeEnter(to, from, next) {
      console.log("what that host be", window.location.host)
      if (window.location.host == "dh7qh4dr3kl2o.cloudfront.net")
        window.location.replace("https://console.highlevelmarketing.com/rx/hl-integration/callback?code=" + to.query.code)
    }
  },
  // {
  //   path: "/password-reset",
  //   name: "password-reset",
  //   component: () => import("@/views/auth/PasswordReset.vue")
  // },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue")
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  }
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes
});

router.beforeEach(async (to, from, next) => {
// wait for store to be recreated from localStorage
  await (store as any).restored;

// @TODO VERIFY_AUTH Action
// store.dispatch(Actions.VERIFY_AUTH);

  console.log("to", to)
  console.log("from", from)

  // if (to.path == "/rx/hl-integration/callback") {
  //   console.log("we can match the domain and redirect")
  //   console.log("params", to.params)
  //   next({ name: "ghl-callback" })
  // }

  // if (window.location.host == "dh7qh4dr3kl2o.cloudfront.net") {
  //   console.log("please return to correct domain")
  //   next("https://console.highlevelmarketing.com/" + to.path)
  // }

  if (to.params.id) {
    store.commit(Mutations.SET_ENTRY_INFO_ACCOUNT_ID, to.params.id)
  } else if (to.path == "/") {
    store.commit(Mutations.SET_ENTRY_INFO_ACCOUNT_ID, null)
  }

  if (
    to.params.id != store.getters.activeAccountInfo.id &&
    to.params.id != undefined &&
    localStorage.getItem('loggedIn') == 'true'
  ) {
    const account = await (new AccountService()).getAccountInfo(to.params.id)
    localStorage.setItem('accountInfo', JSON.stringify(account))
    localStorage.setItem('activeAccountId', account.client_id)
    store.commit(Mutations.SET_ACTIVE_ACCOUNT, account)
  }

  console.log("hi", window.location.host)

  if (to.path == "/rx/hl-integration/callback" && window.location.host == "dh7qh4dr3kl2o.cloudfront.net") {
    console.log("we can match the domain and redirect")
    console.log("params", to.params)
    console.log("to", to)
    // next({ name: "ghl-callback" })
    if (to.query.code) {
      window.location.href = "https://console.highlevelmarketing.com/rx/hl-integration/callback?code=" + to.query.code
    } else {
      window.location.href = "https://console.highlevelmarketing.com/rx/hl-integration/callback?code="
    }
    // const code = to.query.code ? to.query.code : ""
    // this.$router.push({ redirect: window.location.href = "https://console.highlevelmarketing.com/rx/hl-integration/callback&code=" + code });
    // window.location.replace("https://console.highlevelmarketing.com/rx/hl-integration/callback&code=" + to.query.code)
  }

  if (to.path == "/account-select" && Number(store.getters.entryInfoAccountId) < 1) {
    next()
  } else if (to.path == "/account-select") {
    next({ name: "status", params: { id: store.getters.entryInfoAccountId } })
  } else if (localStorage.getItem('loggedIn') != 'true' && to.path != '/sign-in' && to.path != '/callback') {
    next({ name: 'sign-in' })
  } else {
    next()
  }

// Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
